import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Loading from "../../../components/Loading/Loading";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import KeywordField from "../../../components/KeywordField/KeywordField";

import useCondition from "../ConditionProfile/hooks/useCondition";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

import "./ConditionEdit.css";

function ConditionEdit() {
  const { conditionId } = useParams();
  const { condition, loading, error } = useCondition(conditionId);

  const [formData, setFormData] = useState({
    name: "",
    condition_ai_image1: "",
    alt_keywords: [],
    root_keywords: [],
    negative_words: [],
    category: "",
  });

  useEffect(() => {
    if (condition) {
      setFormData({
        name: condition.name || "",
        condition_ai_image1: condition.condition_ai_image1 || "",
        alt_keywords: condition.alt_keywords || [],
        root_keywords: condition.root_keywords || [],
        negative_words: condition.negative_words || [],
      });
    }
  }, [condition]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length) {
      const file = files[0];
  
      // Validate file type
      const supportedTypes = ["image/png", "image/jpeg", "image/webp"];
      if (!supportedTypes.includes(file.type)) {
        alert("Unsupported file type. Please upload a PNG, JPEG, or WEBP image.");
        return;
      }
  
      // Create a temporary URL for the image preview
      const previewURL = URL.createObjectURL(file);
  
      setFormData((prev) => ({
        ...prev,
        condition_ai_image1: previewURL, // Update for preview
        fileToUpload: file, // Store the actual file separately
      }));
    }
  };

  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior (e.g., opening the file in a new tab)
    
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      handleFileChange({ target: { files } }); // Reuse the existing file handling logic
    }
  };

  const handleAppendItem = (list, item) => {
    if (!item.trim()) return; // Prevent empty strings
    setFormData((prev) => {
      const currentList = prev[list] || [];
      const normalizedItem = item.trim().toLowerCase(); // Normalize case
      const isDuplicate = currentList.some(
        (existingItem) => existingItem.toLowerCase() === normalizedItem
      ); // Case-insensitive check
      if (isDuplicate) return prev; // Avoid duplicates
      return {
        ...prev,
        [list]: [...currentList, item.trim()],
      };
    });
  };

  const handleRemoveItem = (list, index) => {
    setFormData((prev) => ({
      ...prev,
      [list]: prev[list].filter((_, i) => i !== index),
    }));
  };

  const handleUpdateCondition = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("alt_keywords", JSON.stringify(formData.alt_keywords));
    formDataToSend.append("root_keywords", JSON.stringify(formData.root_keywords));
    formDataToSend.append("negative_words", JSON.stringify(formData.negative_words));

    if (formData.condition_ai_image1 instanceof File) {
      formDataToSend.append(
        "condition_ai_image1",
        formData.condition_ai_image1
      );
    }

    try {
      // fake response for now
      const response = {
        ok: true,
      }

      if (!response.ok) {
        throw new Error("Failed to update condition");
      }

      alert("Condition updated successfully");
    } catch (error) {
      console.error("Error updating condition:", error);
      alert("Failed to update condition");
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <>
      <Helmet>
        <title>{`Genti - Edit Condition`}</title>
      </Helmet>
      <ErrorDisplay error={error} />
    </>;
  }

  return (<>
    <Helmet>
      <title>{`Genti - Edit Condition`}</title>
    </Helmet>
    
    <div className="admin-page cluster-edit page-base">
      <form
        onSubmit={handleUpdateCondition}
        className="space-y-4 flex flex-col"
      >
        <div>
          <h1 className="mb-2 text-2xl text-deep-space dark:text-[#c0d7ed]">
            Edit Condition
          </h1>
          <section className="page-section">
            <h2 className="section-header">Condition Information</h2>
            <div className="p-4 space-y-4">
              <div className="form-group w-full flex flex-col">
                <label className="form-label">Name</label>
                <input
                  className="py-2 px-4 rounded text-lg"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  autoCorrect="off"
                  autoComplete="off"
                />
              </div>

              <div className="form-group w-full flex flex-col">
                <label className="form-label">Condition Group</label>
                <select className="min-h-[60px] py-2 px-4 rounded text-lg" name="category" value={formData.category} onChange={handleInputChange}>
                  <option value="Dermatological Disorders">Dermatological Disorders</option>
                  <option value="Hematologic Disorders">Hematologic Disorders</option>
                  <option value="Infectious Diseases">Infectious Diseases</option>
                </select>
              </div>

              <div className="form-group space-y-2">
                <label className="form-label" aria-hidden="true" htmlFor="file-upload">Condition Image</label>
                <div className="flex items-center gap-4 p-4 pt-0">
                  <img
                    src={formData.condition_ai_image1}
                    alt={formData.name || "Uploaded image preview"}
                    className="page-section h-48 w-48 object-cover rounded-lg shadow-lg border border-gray-300 aspect-square"
                  />
                  <label
                    htmlFor="file-upload"
                    className="flex flex-col items-center justify-center w-full h-48 border-2 border-dashed border-gray-500 rounded cursor-pointer bg-gray-900 bg-opacity-50 hover:border-[#C0D7ED] transition focus:outline-none"
                    onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
                    onDrop={handleDrop} // Use the decoupled function
                  >
                    <div className="flex flex-col items-center text-gray-400">
                      <FontAwesomeIcon
                        className="mb-2"
                        icon={faCamera}
                        size="3x"
                      />
                      <p className="mb-1 text-sm">
                        Drag an image or{" "}
                        <span className="text-indigo-400 underline">
                          click here
                        </span>{" "}
                        to upload
                      </p>
                      <p className="text-xs">Supports JPEG, PNG, WEBP</p>
                    </div>
                    <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      name="condition_ai_image1"
                      onChange={handleFileChange}
                      accept="image/png, image/jpeg, image/webp"
                    />
                  </label>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="page-section">
          <h2 className="section-header">Keywords</h2>

          <div className="p-4 flex flex-col gap-4">
            <KeywordField
              label="Alternate Names"
              placeholder="Add Alternate Names"
              list={formData.alt_keywords || []}
              type="alt_keywords"
              onAdd={handleAppendItem}
            >
              { formData.alt_keywords?.map((keyword, index) => (
                <KeywordField.KeywordItem
                  onRemove={() => handleRemoveItem("alt_keywords", index)}
                  key={`alt_keywords-${index}`}
                >
                  {keyword}
                </KeywordField.KeywordItem>
              ))}
            </KeywordField>

            <KeywordField
              label="Root Keywords"
              placeholder="Add Roote Keywords"
              list={formData.root_keywords || []}
              type="root_keywords"
              onAdd={handleAppendItem}
            >
              { formData.root_keywords?.map((keyword, index) => (
                <KeywordField.KeywordItem
                  onRemove={() => handleRemoveItem("root_keywords", index)}
                  key={`root_keywords-${index}`}
                >
                  {keyword}
                </KeywordField.KeywordItem>
              ))}
            </KeywordField>

            <KeywordField
              label="Top Negative Keywords"
              placeholder={"Add Top Negative Keyword"}
              list={formData.negative_words || []}
              type="negative_words"
              onAdd={handleAppendItem}
            >
              { formData.negative_words?.map((keyword, index) => (
                <KeywordField.KeywordItem
                  onRemove={() => handleRemoveItem("negative_words", index)}
                  key={`negative_words-${index}`}
                >
                  {keyword}
                </KeywordField.KeywordItem>
              ))}
            </KeywordField>
          </div>
        </section>

        <button type="submit" className="btn w-full max-w-[500px] self-center">
          Submit
        </button>
      </form>
    </div>
  </>);
}

export default ConditionEdit;
