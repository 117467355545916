// react
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// hooks
import useDiscover from "./hooks/useDiscover";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faClose } from "@fortawesome/free-solid-svg-icons";


// components
import ProjectFilter from "../../components/ProjectFilter/ProjectFilter";
import CompaniesPagination from "./CompaniesPagination";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import ConditionsList from "./ConditionsList/ConditionsList";
import ProjectsTable from "./ProjectsTable/ProjectsTable";

// styles
import "./Discover.css";

const Companies = () => {
  const {
    projects,
    conditions,
    loading,
    error,
    currentPage,
    setCurrentPage,
    totalPages,
    search,
    setSearch,
  } = useDiscover();

  const [selectedDifficulties, setSelectedDifficulties] = useState({
    lowest: true,
    low: true,
    moderate: true,
    high: true,
    highest: true,
  });

  // to focus on search input
  const searchInputRef = useRef(null);

  // Function to handle toggle changes
  const handleToggle = (difficulty) => {
    setSelectedDifficulties((prev) => ({
      ...prev,
      [difficulty]: !prev[difficulty],
    }));
  };

  const handleSetSearch = (e) => {
    // if leading space, do not set search
    if (e.target.value.startsWith(" ")) return;

    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  // if (error) return <p>Error loading projects!</p>;

  return (
    <>
      <Helmet>
        <title>Discover Opportunities | Genti</title>
        <meta property="og:title" content="Discover Opportunities | Genti" />
        <meta property="og:description" content="A new horizon. Empowering self-care." />
        <meta property="og:image" content="https://genti.ai/og-image.png" />
        <meta property="og:url" content="https://genti.ai" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://genti.ai/og-image.png" />
        <meta name="twitter:title" content="Genti" />
        <meta name="twitter:description" content="A new horizon. Empowering self-care." />
        <meta name="twitter:site" content="@ideaevolver" />
      </Helmet>

      <div className="companies-page page-base overflow-hidden">
        <div className="page-header">
          <div className="col-1">
            <h1 className="mb-1">Discover</h1>
            <p className="ml-3 text-[#838190]">Search for Opportunities</p>
          </div>

          <div className="col-2">
            <Link className="btn header-btn" to="/project-status">
              <span className="hidden md:inline-block">Update Queue</span>
              <FontAwesomeIcon icon={faClockRotateLeft} />
            </Link>
          </div>
        </div>

        <div className="flex flex-col my-2 md:my-4 md:mb-12 md:flex-row gap-2">
          <form action="" className="relative search-bar flex-1" role="search">
            <label className="mb-2 text-xs opacity-0" htmlFor="search">
              Company Search
            </label>
            <div className="relative">
              <input
                className={`w-full p-4 rounded border border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50 focus:ring focus:ring-[#0561b780] focus:dark:ring-[#0561b7] ${
                  error ? "cursor-not-allowed" : ""
                }`}
                type="text"
                value={search}
                onChange={handleSetSearch}
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder="Search for Conditions, Companies, and Brands"
                disabled={error}
                ref={searchInputRef}
              />
              { search !== '' && <button
                className="absolute top-1/2 right-2 -translate-y-1/2 rounded-full h-10 w-10 text-lg hover:bg-black hover:bg-opacity-10 dark:hover:bg-white dark:hover:bg-opacity-20"
                onClick={handleClearSearch}
                type="button"
              >
                <FontAwesomeIcon icon={faClose} />
              </button>}
            </div>
          </form>

          <ProjectFilter
            selectedDifficulties={selectedDifficulties}
            handleToggle={handleToggle}
          />
        </div>

        {error && <ErrorDisplay error={error} />}

        { search.length >= 3 && !!conditions && conditions.length > 0 && !loading && !error && (
          <ConditionsList conditions={conditions} />
        )}

        { search.length >= 3 && !loading && !error && (
          <div className="page-section w-full rounded overflow-x-scroll">
            <ProjectsTable projects={projects} loading={loading} />
          </div>
        )}

        <CompaniesPagination
          loading={loading}
          error={error}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default Companies;
