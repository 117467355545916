import { useTheme } from "../../../utils/ThemeManager";

/**
 * Groups an array of items into subarrays of a specified size.
 *
 * @param {Array} array - The original array to group.
 * @param {number} size - The number of items per group.
 * @returns {Array} - An array of grouped subarrays.
 */
export const groupQuestions = (array, size) => {
  // return if the array is empty or not an array
  if (!Array.isArray(array) || !array.length) return [];

  const groups = [];
  for (let i = 0; i < array.length; i += size) {
    groups.push(array.slice(i, i + size));
  }
  return groups;
};

const getImagePath = (theme, audience, sentiment) => {
  const normalizedAudience = audience?.toLowerCase();
  const normalizedSentiment = sentiment?.toLowerCase();
  const imageName =
    normalizedAudience === 'hcp' || normalizedAudience === 'healthcare provider'
      ? `hcp/${normalizedSentiment}`
      : normalizedSentiment;
  return new URL(`/sentiment-images/${theme}/${imageName || 'default'}.webp`, window.location.origin).href;
};

const QuestionCard = ({ question }) => {
  const { theme } = useTheme();
  const imagePath = getImagePath(theme, question.audience, question.sentiment);

  return (
    <div className="
      relative 
      rounded
      overflow-hidden
      border
      border-[#0561B7]
      border-dotted
      border-opacity-[0.75]
    "
    >
      <div className="section-header p-2 leading-[1.0]">
        <h3>
          <span className="text-xl font-bold">{question.question}</span>
        </h3>
        <p className="ml-4">
          <span className="text-base font-normal">{question.description}</span>
        </p>
      </div>

      {/* Body of the card */}
      <div className="p-4 flex gap-6">
        <div className="img-container flex-shrink-0 max-w-[140px]">
          <img
            src={imagePath}
            alt={`a ${question.audience} with ${question.sentiment} sentiment`}
            className="w-[140px] object-cover aspect-square border border-[#C0D7ED]"
          />

          <div className="min-h-[50px] px-2 font-sans grid place-items-center text-center text-sm py-1 bg-[#F5F5F5] dark:bg-[#0561B7] dark:bg-opacity-10 border border-t-0 border-dotted border-watermelon-500">{ question.sentiment } { question.audience }</div>
        </div>
        <ul className="grid grid-rows-2 items-start">
          <li className="flex flex-col gap-0.5">
            <h4 className="text-deep-space dark:text-[#C0D7ED] text-xl font-bold"><span className="py-1">Gap</span></h4>
            <p className='ml-2 px-2 text-lg border-l border-watermelon-500'>{question.gap_analysis}</p>
          </li>
          <li className="flex flex-col gap-0.5">
            <h4 className="text-deep-space dark:text-[#C0D7ED] text-xl font-bold"><span className="py-1">Idea</span></h4>
            <p className='ml-2 px-2 text-lg border-l border-watermelon-500'>{question.idea}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};


export default QuestionCard;