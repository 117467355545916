import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import useCluster from "./hooks/useCluster";

import Loading from "../../components/Loading/Loading";

import ChartSlider from "../../components/ChartSlider/ChartSlider";
import Slider from "../../components/Slider/Slider";
import SearchVolMonth from "./Visualizations/SearchVolMonth";
import SearchVolBubble from "./Visualizations/SearchVolBubble";
import PaidSERP from "./Visualizations/PaidSERP";
import ClusterSERPFeatures from "./Visualizations/ClusterSERPFeatures";
import ClusterTable from "./Visualizations/ClusterTable";
import ClusterOverview from "./ClusterOverview/ClusterOverview";
import ClusterLightbox from "./ClusterLightbox/ClusterLightbox";

import QuestionCard, { groupQuestions } from "./QuestionCard/QuestionCard";

import {
  formatCurrency,
  formatFloat,
  formatPercent,
} from "../../utils/tableUtils";

import "./Cluster.css";

import fallback from "../../assets/img/fallback.webp";

function Cluster() {
  const location = useLocation();
  const history = location.state?.history || [];
  const { clusterId, profileId } = useParams();
  const { cluster, loading, error } = useCluster(clusterId, profileId);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  console.log(cluster);

  // set element with class App to overflow hidden when lightbox is open
  useEffect(() => {
    const appElement = document.querySelector(".App");
    const htmlElement = document.querySelector("html");
  
    if (appElement && htmlElement) {
      if (lightboxOpen) {
        appElement.style.overflow = "hidden";
        htmlElement.style.overflow = "hidden";
      } else {
        appElement.style.overflow = "auto";
        htmlElement.style.overflow = "auto";
      }
    }
  }, [lightboxOpen]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (<>
    <Helmet>
      <title>{`Genti - Idea`}</title>
      <meta property="og:description" content="A new horizon. Empowering self-care." />
    </Helmet>

      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">Error fetching data</h1>
            <p className="text-red-500 font-mono">
              {error?.message || "Something went wrong."}
            </p>
          </div>
        </section>
      </div>
    </>);
  }

  if (cluster == null || Object.keys(cluster).length === 0) {
    return (<>
      <Helmet>
        <title>{`Genti - Idea`}</title>
        <meta property="og:description" content="A new horizon. Empowering self-care." />
      </Helmet>

      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">No data available</h1>
            <p className="font-mono">
              No cluster data available for this profile.
            </p>
          </div>
        </section>
      </div>
    </>);
  }

  const groupedQuestions = groupQuestions(cluster.cluster_data?.popular_questions, 2);

  const shareImage = cluster?.cluster_image || fallback; // Use the hosted fallback image if cluster_image is not available

  return (
    <>
      <Helmet>
        <title>{cluster?.cluster_data?.idea?.title || "Idea"} | Genti</title>
        <meta property="og:image" content={shareImage} />
        <meta property="og:description" content={cluster?.cluster_data?.summary  || ""} />
        <meta property="og:url" content={`https://genti.ai/cluster/${clusterId}/${profileId}`} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={cluster?.cluster_data?.idea?.title || "Idea"} />
        <meta name="twitter:description" content={cluster?.cluster_data?.summary || ""} />
        <meta property="twitter:image" content={shareImage} />
      </Helmet>

      <div className="cluster page-base space-y-4 md:space-y-8">

        {/* cluster info */}
        <section className="page-section cluster-info">
          <img
            src={cluster?.cluster_image || fallback}
            alt=""
            className="cluster-img md:col-span-1 lg:row-span-2"
            onClick={() => setLightboxOpen(true)}
          />

          <div className="section-header h-full flex flex-col gap-4 col-span-full md:col-span-1 md:row-span-1">
            <h1 className="">{ cluster?.cluster_data?.title }</h1>
            <p className="ml-2 px-2 border-l border-watermelon-500 mt-3">{cluster?.cluster_data?.summary }</p>
          </div>

          <div className="h-full col-span-full lg:col-span-1 lg:col-start-2 lg:row-start-2 grid grid-cols-2 grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 p-4 gap-4">
            <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
              <h2 className="section-header text-base text-center p-2 font-bold">Category</h2>
              <p className="flex-1 text-xl text-center font-bold content-center">{ cluster?.cluster_data?.primary_category }</p>
            </div>

            <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
              <h2 className="section-header text-base text-center p-2 font-bold">Reach</h2>
              <p className="flex-1 text-2xl text-center font-bold content-center">{ formatFloat(cluster?.cluster_data?.annual_search_volume, 0)}</p>
            </div>

            <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
              <h2 className="section-header text-base text-center p-2 font-bold">Difficulty</h2>
              <p className="flex-1 text-2xl text-center font-bold content-center">{ formatPercent(cluster?.cluster_data?.avg_diff, 0) }</p>
            </div>

            <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
              <h2 className="section-header text-base text-center p-2 font-bold">Opportunity</h2>
              <p className="flex-1 text-2xl text-center font-bold content-center">{ formatFloat(cluster?.cluster_data?.avg_opp, 0) }</p>
            </div>

          </div>
        </section>

        {/* most popular questions */}
        <section className="page-section">
          <h2 className="section-header">Most Popular Questions</h2>

          { cluster.cluster_data?.popular_questions ? <Slider>
            { groupedQuestions.map((questionPair, slideIndex) => (
              <div className="slide flex flex-col gap-4 p-4" key={slideIndex}>
                {questionPair.map((question, index) => (
                  <div key={index} className="flex-1">
                    <QuestionCard
                      question={question}
                      index={slideIndex * 2 + index}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Slider>
          : <p className="p-4 text-center">No popular questions available for this cluster.</p>
          }
        </section>

        {/* keywords */}
        <section className="page-section">
          <div>
            <h2 className="section-header">Keyword Intent</h2>

            <div className="cluster-intent p-4">
            <h3 className="sr-only">Intent</h3>
            <ol className="grid md:grid-cols-2 gap-x-4 gap-y-4 w-full">
              {cluster.cluster_data?.search_motivations?.map(
                (reason, index) => (
                  <li key={index} className="border-b border-dotted border-deep-space dark:border-[#C0D7ED] border-opacity-25 p-4">
                    <h3 className="font-bold text-lg">{reason.title}</h3>
                    <p className="">{reason.description}</p>
                  </li>
                )
              )}
            </ol>
          </div>

            <ChartSlider>
              <SearchVolMonth
                data={cluster.cluster_data?.total_search_volume_by_month}
              />
              <SearchVolBubble
                data={
                  cluster.cluster_data?.visualizations?.search_volume_by_type
                }
              />
              <PaidSERP
                data={cluster.cluster_data?.visualizations?.paid_serp}
              />
              <ClusterSERPFeatures
                data={cluster.cluster_data?.visualizations?.serp_features}
              />
            </ChartSlider>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="info-boxes grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">Share</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">  { formatPercent(cluster?.cluster_data?.visualizations?.cluster_share?.[1]?.y ?? 0, 2) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_keyword_count, 0) }/kws</div>
                </div>
              </div>
            </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                  <h2 className="font-bold text-center">
                    Total Volume (mo/yr)
                  </h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_search_volume, 0) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.annual_search_volume, 0) }</div>
                  </div>
                </div>
            </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">Difficulty (avg/high)</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.avg_diff, 0) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.most_difficult_diff?.max_kwd_value, 0) }</div>
                </div>
              </div>
            </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">CPC (avg/high)</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.avg_cpc) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.most_expensive_cpc?.max_cpc_value, 2) }</div>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="overflow-x-auto">
            <ClusterTable
              data={cluster.cluster_data?.visualizations?.keywords}
              profileId={profileId}
              history={history}
            />
          </div>
        </section>
      </div>

      { lightboxOpen && (
        <ClusterLightbox
          cluster={cluster}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </>
  );
}

export default Cluster;

