import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import './Admin.css';

function Admin() {
  return <>
    <Helmet>
      <title>{`Genti - Admin Dashboard`}</title>
    </Helmet>

    <div className="page-base admin-page dashboard">
      <section className="page-section">
        <h1 className="section-header">Admin Dashboard</h1>
        <div className="p-4 flex flex-col md:flex-row gap-4">
          <Link className="btn flex-1" to="/admin/condition-library">Condition Library</Link>
          <Link className="btn flex-1" to="/admin/forecast-leaderboard">Forecast Leaderboard</Link>
        </div>
      </section>

    </div>
  </>
}

export default Admin;
