// react
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";

// local components
import DistributionSliders from "./DistributionSliders/DistributionSliders";
import SliderInput from "./SliderInput/SliderInput";
import KeywordField from "../../components/KeywordField/KeywordField";
import InputField from "./InputField";
import FunnelChart from "./Visualizations/FunnelChart";

import { formatCurrency, formatPercent } from "../../utils/tableUtils";

// axios
import { $axios } from "../../services/axiosInstance";

import useProject from "../../hooks/useProject";
import useConditions from "../../hooks/useConditions";

import "./ProjectEdit.css";

const ProjectEdit = () => {
  const uuid = useParams().projectId;
  const navigate = useNavigate();

  const { project, loading, error } = useProject(uuid);
  const { conditions, loading: conditionsLoading, error: conditionsError } = useConditions();

  const [formError, setFormError] = useState({
    goals: false,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState({
    parent_company: "",
    name: "",
    url: "",
    brand_goals: "",
    alt_keywords: [],
    root_keywords: [],
    negative_words: [],
    price_monthly: 0,
    cost_monthly: 0,
  });

  const [goals, setGoals] = useState([
    { name: "Increase Brand Awareness", description: "Drive recognition and understanding of the brand product", y: 0 },
    { name: "Educate Target Audiences", description: "Build awareness and knowledge among targeted customers", y: 0 },
    { name: "Drive Engagement", description: "Encourage active interaction and participation with the brand", y: 0 },
    { name: "Generate Leads", description: "Attract and capture potential customer interest", y: 0 },
    { name: "Boost Website Traffic", description: "Increase visitor volume to enhance online visibility", y: 0 },
    { name: "Support Conventions", description: "Strengthen brand presence through event involvement", y: 0 },
    { name: "Optimize User Experience", description: "Streamline interactions to improve customer satisfaction", y: 0 },
    { name: "Improve Products and Services", description: "Enhance offerings to better meet market needs", y: 0 },
  ]);

  const [forecast, setForecast] = useState({
    visits: { name: "Visits", y: 1 },
    starts: { name: "Start", y: 0 },
    completions: { name: "Completions", y: 1 },
    outcomes: { name: "Outcome", y: 0 },
    ok_to_use: { name: "Ok to Use", y: 0 },
    purchase: { name: "Purchase", y: 0 },
  });

  useEffect(() => {
    if (project) {
      setFormData({
        parent_company_name: project.parent_company_name || "",
        condition: project.condition_analysis_name || "",
        name: project.name || "",
        url: project.url || "",
        brand_goals: project.brand_goals || "",
        alt_keywords: project.alt_keywords || [],
        root_keywords: project.root_keywords || [],
        negative_words: project.negative_words || [],
        price_monthly: project.price_monthly || 0,
        cost_monthly: project.cost_monthly || 0,
      });

      setGoals(project.goals || goals);

      setForecast({
        visits: { name: "Visits", y: 1 },
        starts: { name: "Start", y: project.starts || 0 },
        outcomes: { name: "Outcome", y: project.outcomes || 0 },
        ok_to_use: { name: "Ok to Use", y: project.ok_to_use || 0 },
        purchase: { name: "Purchase", y: project.purchase || 0 },
        completions: { name: "Completions", y: 1 },
      });
    }
  }, [project]);

  const validateForm = () => {
    let isValid = true; // Track overall validation state

    // Validate goals add up to 100%
    const goalsTotal = goals.reduce((sum, g) => sum + g.y, 0);
    if (goalsTotal !== 100) {
      setFormError((prev) => ({ ...prev, goals: true }));
      isValid = false; // Mark validation as failed
    } else {
      setFormError((prev) => ({ ...prev, goals: false })); // Clear goals error
    }

    return isValid; // Return overall validation result
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateForecast = (key, newValue) => {
    setForecast((prev) => ({
      ...prev,
      [key]: { name: prev[key].name, y: Number(newValue) },
    }));
  };

  const handleAppendItem = (list, item) => {
    if (!item.trim()) return; // Prevent empty strings
    setFormData((prev) => {
      const currentList = prev[list] || [];
      const normalizedItem = item.trim().toLowerCase(); // Normalize case
      const isDuplicate = currentList.some(
        (existingItem) => existingItem.toLowerCase() === normalizedItem
      ); // Case-insensitive check
      if (isDuplicate) return prev; // Avoid duplicates
      return {
        ...prev,
        [list]: [...currentList, item.trim()],
      };
    });
  };

  const handleRemoveItem = (list, index) => {
    setFormData((prev) => ({
      ...prev,
      [list]: prev[list].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    setFormError({ forecast: false, goals: false });

    if (!validateForm()) {
      setFormLoading(false);
      return;
    }

    // Remove UUID from project data
    const { uuid, ...projectData } = project;
    const updatedProject = {
      ...projectData,
      url: formData.url,
      condition_analysis_name: formData.condition,
      brand_goals: formData.brand_goals,
      negative_words: formData.negative_words,
      starts: forecast.starts.y,
      outcomes: forecast.outcomes.y,
      ok_to_use: forecast.ok_to_use.y,
      purchase: forecast.purchase.y,
      price_monthly: formData.price_monthly,
      cost_monthly: formData.cost_monthly,
      alt_keywords: formData.alt_keywords,
      root_keywords: formData.root_keywords,
      goals: goals,
    };

    // delete the uuid property from the project object
    delete updatedProject.uuid;

    try {
      const { data } = await $axios.patch(
        `/company/project/${project.uuid}/update/`,
        updatedProject
      );
      if (data.last_run === null) {
        navigate(`/discover`);
      } else {
        navigate(`/project/${project.uuid}`);
      }
    } catch (error) {
      setFormError(error.message);
      console.error(error);
    } finally {
      setFormLoading(false);
    }
  };

  if (loading || conditionsLoading) {
    return <Loading />;
  }

  if (error || conditionsError) {
    return <>
      <Helmet>
        <title>Edit Project | Genti</title>
      </Helmet>

      <ErrorDisplay error={error || conditionsError} />
    </>;
  }

  return (
    <>
      <Helmet>
        <title>{`Edit ${ project.name || "Project"} | Genti`}</title>
      </Helmet>
      
      <div
        className={`project-add-edit page-base ${
          formLoading ? "animate-pulse" : ""
        }`}
      >
        <h1 className="text-2xl text-deep-space dark:text-[#C0D7ED] font-bold">
          Edit Project
        </h1>
        <form className="form space-y-12" onSubmit={handleSubmit}>
          <div className="page-section">
            <h2 className="section-header">Project Info</h2>
            <div className="form-group-container">
              {/* Company Name */}
              <InputField
                id="companyName"
                name="company_name"
                label="Company Name"
                value={formData.parent_company_name}
                onChange={handleInputChange}
                disabled={true}
              />
              {/* Project Name */}
              <InputField
                id="projectName"
                name="name"
                label="Project Name"
                value={formData.name}
                onChange={handleInputChange}
                disabled={true}
              />
              {/* Project URL */}
              <InputField
                id="projectUrl"
                className="md:col-span-full"
                name="url"
                label="Project URL"
                value={formData.url}
                onChange={handleInputChange}
              />
              {/* Condition Dropdown */}
              <div className="form-group">
                <label htmlFor="condition">Condition</label>
                <select
                  id="condition"
                  name="condition"
                  value={formData.condition}
                  onChange={handleInputChange}
                >
                  <option value="">Select Condition</option>
                  {conditions && conditions.map((condition) => (
                    <option key={condition.uuid} value={condition.name}>
                      {condition.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div
            className={`page-section ${
              formError.goals
                ? "border-watermelon-500 dark:border-watermelon-500"
                : ""
            }`}
          >
            <h2 className="section-header">Audience and Goals</h2>

            <div className="p-4 space-y-2">
              <DistributionSliders goals={goals} setGoals={setGoals} />

              <div>
                <div className="form-group pt-0 flex">
                  <label htmlFor="brand_goals" className="block text-deep-space dark:text-white">Brand Goals Description</label>

                  <textarea
                    className="resize-none flex-1 border-none p-2"
                    id="brand_goals"
                    name="brand_goals"
                    value={formData.brand_goals}
                    onChange={handleInputChange}
                    rows="5"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="page-section">
            <h2 className="section-header">Keywords</h2>

            <div className="p-4 flex flex-col gap-4">
              <div className="form-group">
                <KeywordField
                  label="Alternate Names"
                  placeholder="Add Alternate Names"
                  list={formData.alt_keywords || []}
                  type="alt_keywords"
                  onAdd={handleAppendItem}
                >
                  {formData.alt_keywords?.map((keyword, index) => (
                    <KeywordField.KeywordItem
                      onRemove={() => handleRemoveItem("alt_keywords", index)}
                      key={`alt_keywords-${index}`}
                    >
                      {keyword}
                    </KeywordField.KeywordItem>
                  ))}
                </KeywordField>
              </div>

              <div className="form-group">
                <KeywordField
                  label="Root Keywords"
                  placeholder="Add Root Keywords"
                  list={formData.root_keywords || []}
                  type="root_keywords"
                  onAdd={handleAppendItem}
                >
                  {formData.root_keywords?.map((keyword, index) => (
                    <KeywordField.KeywordItem
                      onRemove={() => handleRemoveItem("root_keywords", index)}
                      key={`root_keywords-${index}`}
                    >
                      {keyword}
                    </KeywordField.KeywordItem>
                  ))}
                </KeywordField>
              </div>

              <div className="form-group">
                <KeywordField
                  label="Top Negative Keywords"
                  placeholder={"Add Top Negative Keyword"}
                  list={formData.negative_words || []}
                  type="negative_words"
                  onAdd={handleAppendItem}
                >
                  {formData.negative_words?.map((keyword, index) => (
                    <KeywordField.KeywordItem
                      onRemove={() => handleRemoveItem("negative_words", index)}
                      key={`negative_words-${index}`}
                    >
                      {keyword}
                    </KeywordField.KeywordItem>
                  ))}
                </KeywordField>
              </div>
            </div>
          </div>

          <div
            className={`page-section ${
              formError.forecast
                ? "border-watermelon-500 dark:border-watermelon-500"
                : ""
            }`}
          >
            <h2 className="section-header">ACNU Forecast Settings</h2>

            <div className="p-4 space-y-4">
              <div>
                <h3 className="text-deep-space dark:text-[#C0D7ED] text-lg font-bold">
                  Adjust Unit Profitability
                </h3>
                <p className="ml-3 text-sm">Unit = 30-day supply</p>
              </div>

              <div className="grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 gap-4">
                <SliderInput
                  label="30-day Supply Price"
                  min={0}
                  max={500000}
                  value={formData.price_monthly}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      price_monthly: Number(e.target.value),
                    }))
                  }
                  formatFn={formatCurrency}
                />

                <SliderInput
                  label="30-day Supply Cost"
                  min={0}
                  max={500000}
                  value={formData.cost_monthly}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      cost_monthly: Number(e.target.value),
                    }))
                  }
                  formatFn={formatCurrency}
                />
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div>
                <h3 className="text-deep-space dark:text-[#C0D7ED] text-lg font-bold">
                  Adjust Visitor Conversion Rates
                </h3>
                <p className="ml-3 text-sm">Unit = 30-day supply</p>
              </div>

              <div className="md:pb-4 grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 gap-12 md:gap-4">
                <div className="grid grid-cols-1 gap-4">
                  <SliderInput
                    label="Start"
                    min={0}
                    max={100}
                    value={forecast.starts.y * 100}
                    onChange={(e) => handleUpdateForecast("starts", e.target.value / 100)}
                    formatFn={formatPercent}
                  />

                  <SliderInput
                    label="Outcome"
                    min={0}
                    max={100}
                    value={forecast.outcomes.y * 100}
                    onChange={(e) => handleUpdateForecast("outcomes", e.target.value / 100)}
                    formatFn={formatPercent}
                  />
                  
                  <SliderInput
                    label="OK to Use"
                    min={0}
                    max={100}
                    value={forecast.ok_to_use.y * 100}
                    onChange={(e) => handleUpdateForecast("ok_to_use", e.target.value / 100)}
                    formatFn={formatPercent}
                  />

                  <SliderInput
                    label="Purchase"
                    min={0}
                    max={100}
                    value={forecast.purchase.y * 100}
                    onChange={(e) => handleUpdateForecast("purchase", e.target.value / 100)}
                    formatFn={formatPercent}
                  />
                </div>

                <FunnelChart data={forecast} />
              </div>
            </div>
          </div>

          <div>
            {formError.goals && (
              <p className="text-center italic text-watermelon-500 dark:text-watermelon-500">
                Audience and Goals must add up to 100%
              </p>
            )}

            <div className="p-4 grid place-items-center">
              <button type="submit" className="btn" disabled={formLoading}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectEdit;
