import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { brandSort, conditionSort, formatMonths, generateGradientColors } from "../../../../utils/chartUtils.js";

import { useTheme } from "../../../../utils/ThemeManager.jsx";

import "./UniverseCharts.css";
import { formatFloat } from "../../../../utils/tableUtils.js";

/*
Condition Journey
- Preventative
- Symptoms
- Diagnosis
- Treatment
- Support
- Clinical Trials
- General

Branded Journey
- Evaluation
- Payment
- Assistance
- Usage
- Side Effects
- General
*/

const UniverseCharts = ({ data, category_type, setSelectedCategory }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  const category_color_grades = {
    "Branded": 6,
    "Condition": 7,
  }

  useEffect(() => {
    if (data == null || Object.keys(data).length === 0) return;

    const colors = theme === 'dark' 
      ? generateGradientColors('#FCFCFC', '#020021', category_color_grades[category_type])
      : generateGradientColors('#C4D6EB', '#0561B7', category_color_grades[category_type]);

    setOptions(barOptions(data, category_type, colors, setSelectedCategory));
  }, [theme]);


  if (data == null || Object.keys(data).length === 0) {
    return (
      <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">
        Keyword Universe data is not available.
      </p>
    );
  }

  return (
    <div className="">
      <ChartDashboard config={options} id={`cluster-feed-${category_type}-bar-container`} />
    </div>
  );
};

export default UniverseCharts;

const barOptions = (data, category_type, colors, setSelectedCategory) => {
  // get names of all categories of the same type
  const categories = Object.entries(data)
    .filter(([key, value]) => value.category_type === category_type)
    .map(([key]) => key)
    .sort((a, b) =>
      category_type === "Branded"
        ? brandSort[a] - brandSort[b]
        : conditionSort[a] - conditionSort[b]
    );

  // stacked bar chart
  const universeBar = categories.map((category) => {
    return {
      name: category,
      data: Array.from({ length: 12 }, (_, monthIndex) => {
        return data?.[category]?.search_volume_monthly_totals?.[monthIndex] || 0;
      }),
      color: colors[categories.indexOf(category)],
      visible: category.toLowerCase() === "general branded" ? false : true, // Disable General Branded by default
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${category_type}-keywords-stacked-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${category_type}-keywords-stacked-cell`,
        chartOptions: {
          colors: colors,
          chart: {
            styledMode: false,
            type: "column",
            height: 450,
          },
          sync: {
            highlight: true,
          },
          title: {
            text: null,
          },
          xAxis: {
            // use the first object of the data object
            categories: formatMonths(
              data[Object.keys(data)[0]].search_volume_months
            ),
          },
          yAxis: {
            title: {
              text: "Average Search Volume",
            },
            min: 0,
            stackLabels: {
              enabled: true,
              textOutline: "none",
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatFloat(this.total, 0);
              },
              style: {
                textOutline: "none",
              }
            },
          },
          legend: {
            itemStyle: {
              fontSize: "14px",
            }
          },
          tooltip: {
            formatter: function () {
              return formatBarChartTooltip(this);
            },
          },
          plotOptions: {
            series: {
              stacking: "normal",
              events: {
                click: function () {
                  setSelectedCategory(this.name);
                }
              }
            },
          },
          series: universeBar,
        },
      },
    ],
  };
};

// tooltip pointFormatter function
export const formatBarChartTooltip = function(point) {
  const coloredDot = `<span style="color:${point.color};">● </span>`;
  const formattedOriginalValue = Highcharts.numberFormat(point.y, 0);

  return `${coloredDot}<b>${point.series.name}</b><br>
  <b> Search Volume:</b> ${formattedOriginalValue}`;
};