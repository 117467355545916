import { useEffect, useState } from "react";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard";
import { useTheme } from "../../../utils/ThemeManager";
import { generateGradientColors } from "../../../utils/chartUtils";

function FunnelChart({ data }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

useEffect(() => {
  if (data == null || Object.keys(data).length === 0) return;

  setOptions(chartOptions(data, theme));
}, [theme, data]);

return <div className="funnel">
  <ChartDashboard config={options} async={true} id="funnel-container" />
</div>
} 

export default FunnelChart;

const chartOptions = (data, theme) => {
// data: {
//   starts: { name: "Start", y: project.starts || 70 },
//   outcomes: { name: "Outcome", y: project.outcomes || 38 },
//   ok_to_use: { name: "Ok to Use", y: project.ok_to_use_percentage || 52 },
//   purchase: { name: "Purchase", y: project.purchase_percentage || 24 },
// }

  const colors = {
    dark: generateGradientColors('#FCFCFC', '#020021', 6),
    light: generateGradientColors('#C4D6EB', '#0561B7', 6),
  }

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `funnel-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `funnel-cell`,
        chartOptions: {
          colors: colors[theme],
          chart: {
              styledMode: false,
              type: 'funnel',
          },
          title: {
              text: 'ACNU Funnel',
          },
          subtitle: {
            text: "Estimates for each step of the ACNU Process"
          },
          legend: {
            enabled: true, // Enable the legend
            layout: "vertical",
            align: "right", // Align to the right
            verticalAlign: "middle", // Center vertically
            padding: 10 // Add some padding for aesthetics
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false // Remove data labels
              },
              center: ["50%", "55%"],
            }
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              // 'this' context in Highcharts refers to the chart’s data point
              return funnelTooltipFormatter(this.point);
            },
          },
          series: [{
            name: 'Unique users',
            showInLegend: true,
            data: [
              { name: data.visits?.name || "Visits", y: 1 },
              { name: data.starts?.name || "Start", y: Number((data.starts?.y ?? 0)) },
              { name: data.completions?.name || "Completions", y: 1 },
              { name: data.outcomes?.name || "Outcome", y: Number((data.outcomes?.y ?? 0)) },
              { name: data.ok_to_use?.name || "Ok to Use", y: Number((data.ok_to_use?.y ?? 0)) },
              { name: data.purchase?.name || "Purchase", y: Number((data.purchase?.y ?? 0)) },
            ]
          }],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500
                },
                chartOptions: {
                  legend: {
                    align: "center",
                    verticalAlign: "bottom",
                    layout: "horizontal",
                    // adjust position of legend 10px from the bottom
                    y: 18,
                  },
                  plotOptions: {
                    series: {
                      center: ["50%", "50%"],
                      width: "100%"
                    }
                  }
                }
              }
            ]
          }
        },
      },
    ],
  };
};

export function funnelTooltipFormatter(point) {
  const colorDot = `<span style="color:${point.color};">●</span>`;
  return `${colorDot} <b>${point.name}</b>: ${Number(point.y * 100)}%`;
}