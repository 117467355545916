const SliderInput = ({ label, min, max, value, onChange, formatFn, percision = 0 }) => (
  <div className="grid items-center mb-4" style={{ gridTemplateColumns: "170px 1fr auto" }}>
    <div className="mr-4">
      <strong className="block text-deep-space dark:text-white">{label}</strong>
    </div>
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      aria-label={label}
      className="flex-1 mr-4 accent-blue-500"
    />
    <span className="w-20 text-center">{formatFn(value, percision)}</span>
  </div>
);

export default SliderInput;