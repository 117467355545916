import { useState, useEffect } from 'react';
import { $axios } from '../services/axiosInstance';

const useConditions = () => {
  const [conditions, setConditions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConditions = async () => {
      try {
        const { data } = await $axios.get(`/company/project_condition_dropdown/`);
        // Create a sorted copy of the data array
        const sortedData = [...data].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setConditions(sortedData);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchConditions();
  }, []);

  return { conditions, loading, error };
};

export default useConditions;
